
import { defineComponent, Ref, ref } from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import GridLine from '@/components/GridLine.vue';
import Dialog from '@/components/Dialog.vue';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID,
};

firebase.initializeApp(firebaseConfig);

interface IValidateCount {
  fullName: Ref<number>;
  eMail: Ref<number>;
  messages: Ref<number>;
}

interface IFormData {
  content: string;
  country: string;
  email: string;
  name: string;
}

export default defineComponent({
  name: 'Contact',
  components: {
    GridLine,
    Dialog,
  },
  setup() {
    const db = firebase.firestore();

    const showDialog = ref(false);
    const fullName = ref('');
    const country = ref('');
    const eMail = ref('');
    const messages = ref('');
    const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    const emptyPattern = /^$/;
    const validateCount: IValidateCount = {
      fullName: ref(0),
      eMail: ref(0),
      messages: ref(0),
    };

    const validation = (strings: string, pattern: RegExp) => pattern.test(strings);

    const resetValidation = () => {
      validateCount.fullName.value = 0;
      validateCount.eMail.value = 0;
      validateCount.messages.value = 0;
    };

    const resetData = () => {
      fullName.value = '';
      country.value = '';
      eMail.value = '';
      messages.value = '';
    };

    const sendContactForm = (data: IFormData) => db.collection('feedback').doc(uuidv4()).set(data);

    const submitForm = async (): Promise<boolean> => {
      resetValidation();
      if (validation(fullName.value, emptyPattern)) {
        validateCount.fullName.value = 1;
      }
      if (validation(eMail.value, emptyPattern) || !validation(eMail.value, emailPattern)) {
        validateCount.eMail.value = 1;
      }
      if (validation(messages.value, emptyPattern)) {
        validateCount.messages.value = 1;
      }
      if (
        validateCount.fullName.value
        || validateCount.eMail.value
        || validateCount.messages.value
      ) {
        return false;
      }

      const data = {
        content: messages.value,
        country: country.value,
        email: eMail.value,
        name: fullName.value,
      };
      await sendContactForm(data).catch((e) => console.error(e));

      showDialog.value = true;
      document.getElementsByTagName('body')[0].classList.add('fixed');
      resetData();

      return true;
    };

    const closeDialog = () => {
      document.getElementsByTagName('body')[0].classList.remove('fixed');
      showDialog.value = false;
    };

    return {
      fullName,
      country,
      eMail,
      messages,
      submitForm,
      validateCount,
      showDialog,
      closeDialog,
    };
  },
});
